@import "colors.scss";
@import "fonts.scss";  

.menu-default{
    height: 100vh;
    position: relative;
}
.menu-default .header{
    background: #FFF;
    height: 55px;
    display: flex;
    align-items: center;
}
.menu-default .header logo{
    display: table;
    margin: 0 auto;
}
.nav-item a{
	text-decoration: none !important;
}
.nav-items {
	flex: 0 0 100%;
}
.nav-item:not(:last-child) {
	border-bottom: solid 0.1px rgba(-10, 0, 0,0.2);
}
.nav-is-toggled .nav-drill {
	transform: translateX(0);
}
.nav-is-toggled::after {
	opacity: 1;
	visibility: visible;
}
.nav-link {
	display: block;
	padding: 0.875em 1em;
	background-color: map-get($colors,one);
	color: #fff;
	font-size: 1rem;
	line-height: 1.5em;
	font-weight: 300;
	font-family: map-get($fonts,one);
	font-size: 14px;
	display: flex;
	align-items: center;
	cursor: pointer;
}
.nav-link .material-icons{
	margin: 0 10px 0 0;
	font-size: 20px;
}
.center{
	display: flex;
	align-items: center;
}
.nav-expand-content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: translateX(100%);
	background-color: map-get($colors,two);
	transition: 0.3s;
	visibility: hidden;
}
.nav-expand-content .nav-item:not(:last-child) {
	border-bottom: solid 1px #eee;
}
.nav-expand-content .nav-link {
	background-color: #daf9f4;
}
.nav-expand-content .nav-back-link::before {
	content: "chevron_left";
	margin-right: 0.5em;
	font-family: "Material Icons";
}
.nav-expand-link {
	display: flex;
	justify-content: space-between;
}
.nav-expand-link::after {
	content: "chevron_right";
	flex: 0 1 auto;
	font-family: "Material Icons";
}
.nav-expand.active > .nav-expand-content {
	transform: translateX(0);
	visibility: visible;
}
.nav-expand .nav-expand-content {
	background-color: #FFF;
}
.nav-expand .nav-expand-content .nav-link {
	background-color: transparent;
    font-weight: 500;
	border-bottom: 1px solid #eee;
}
/*.nav-expand .nav-expand-content .nav-expand-content {
	background-color: #FFF;
}
.nav-expand .nav-expand-content .nav-expand-content .nav-link {
	background-color: #daf9f4;
}
.nav-expand .nav-expand-content .nav-expand-content .nav-expand-content {
	background-color: #daf9f4;
}
.nav-expand .nav-expand-content .nav-expand-content .nav-expand-content .nav-link {
	background-color: #daf9f4;
}
.nav-expand .nav-expand-content .nav-expand-content .nav-expand-content .nav-expand-content {
	background-color: #daf9f4;
}
.nav-expand .nav-expand-content .nav-expand-content .nav-expand-content .nav-expand-content .nav-link {
	background-color: #59e1cb;
}*/
.nav-expand.active > .nav-expand-content {
    transform: translateX(0);
    visibility: visible;
}
.nav-expand-content .nav-link{
    color: map-get($colors,one);
}
.nav-back-link {
	display: flex;
	align-items: center;
	background-color: map-get($colors,one) !important;
	color: #fff !important;
    font-weight: 700 !important;
    padding: 15px 1em;
}
.nav-back-link img{
	margin-left: auto;
}
.nav-link-title{
	font-weight: bold !important;
	color: map-get($colors,two) !important;
	font-weight: 700 !important;
	background: #FFF !important;
}
.nav-link-title a{
	color: map-get($colors,two) !important;
	font-size: 12px;
}
