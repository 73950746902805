@import "colors.scss";

.mat-select-value-text,
.mat-option-text{
    font-size: 12px;
}
.mat-form-field label{
    float: left;
}
.mat-form-field-infix{
    padding-top: 0;
    border-top: 0;
}
.mat-form-field,
.mat-form-field-infix{
    width: 100% !important;
}
.mat-form-field-label{
    position: relative !important;
    font-size: 12px !important;
    top: 2.28125em;
}
.mat-form-field-label-wrapper{
    top: -1.24375em;
}
.mat-form-field .mat-form-field-empty.mat-form-field-label{
    display: block !important;
}
.mat-form-field .mat-form-field-label,
.mat-form-field.mat-focused .mat-form-field-label{
    display: none !important;
}
.mat-slider-horizontal{
    min-width: 100% !important;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background, 
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-form-field-ripple{
    background-color: map-get($colors,one) !important;
}
.mat-tab-group.mat-primary .mat-ink-bar, 
.mat-tab-nav-bar.mat-primary .mat-ink-bar{
    background-color: map-get($colors,five) !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
    border-color: map-get($colors,one) !important;
}
.cdk-global-overlay-wrapper {
    pointer-events: auto !important;
    overflow-y: scroll !important;
    /*align-items: flex-start !important;*/  
    z-index: auto !important; 
}
.cdk-overlay-pane {
    margin: 1rem;
    z-index: 1002; 
}
.cdk-overlay-backdrop{
    z-index: auto !important; 
}
/** TABS **/
.mat-tab-header{
    background: #FFF;
}
.mat-tab-body-content{
    display: table;
    width: 100%;
    padding: 20px 0;
}
.mat-tab-label-content{
    font-size: 12px !important;
}
/** CHECKBOXS **/
mat-checkbox label{
    margin-bottom: 12px;
}
.mat-select-arrow{
    color: map-get($colors,one);
}
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb{
    background-color: map-get($colors,one) !important;
}
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar{
    background-color: map-get($colors,seven) !important;
}
.mat-slide-toggle-content{
    font-size: 12px;
}
.cdk-overlay-connected-position-bounding-box{
    z-index: 10000;
}
.mat-drawer-content{
    overflow-x: hidden;
} 