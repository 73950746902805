@import "colors.scss";

.ui-datepicker:before {
    content: "";
    position: absolute;
    display: block;
    left: 20%;
    top: -11px;
    height: 0;
    width: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid map-get($colors,two);
    border-top: 0 solid transparent;
} 
.ui-datepicker{
    width: 289px;
    margin-top: 7px;
    padding: 0;
    display: none;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: #9c9c9c 0 2px 4px;
    -moz-box-shadow: #9c9c9c 0 2px 4px;
    box-shadow: 0 2px 4px #9c9c9c;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1050!important;
}
.ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: 0;
    height: 30px;
    background: map-get($colors,two);
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    border: 0;
}
.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 38px;
    text-align: center;
    color: #fff;
    font-size: 14px;
}
.ui-datepicker table th {
    padding: 8px 3px;
    text-align: center;
    border: 0;
    color: #fff;
    background: map-get($colors,two);
    font-size: 12px;
    font-weight: 400;
}
.ui-datepicker table tbody td a, .ui-datepicker table tbody td span {
    display: block;
    text-decoration: none;
    text-align: right;
    color: #1c2b39;
    max-width: 100%;
    height: 40px;
    padding: 5px;
    z-index: 1;
    background: #FFF !important;
    border: 1px solid #eee !important;
    font-weight: bold !important;
}
.ui-datepicker table tbody td {
    border-style: solid;
    border-color: #eaf2fc;
    border-width: 1px 1px 0 0;
    font-size: 12px;
    color: #1c2b39 !important;
    font-weight: 700 !important;
    position: relative;
}
.ui-datepicker table {
    width: 100%;
    font-size: .9em;
    border-collapse: collapse;
    margin: 0;
}
.ui-datepicker table tbody {
    border: 1px solid #bcd5f5;
}
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover{
    background: #c4e7ec !important;
    color: map-get($colors,two);
}
.ui-datepicker-current-day::before{
    position: absolute;
    content: "task_alt";
    top: 22px;
    left: 3px;
    font-size: 13px;
    color: map-get($colors,one);
    z-index: 2;
    font-weight: 400;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
.ui-datepicker-next,
.ui-datepicker-prev{
    cursor: pointer !important;
}
/** cart date **/
.cart-date-datepicker .ui-datepicker{
    width: 100% !important;
    border: 0 !important;
}
.cart-date-datepicker .ui-datepicker table th{
    padding: 15px 3px;
}
.cart-date-datepicker .ui-datepicker table tbody td a, 
.cart-date-datepicker .ui-datepicker table tbody td span{
    height: 50px !important;
}
.cart-date-datepicker .ui-datepicker::before{
    display: none !important;
}