/** Array de Cores **/
/** Array de Cores **/
.menu-default {
  height: 100vh;
  position: relative;
}

.menu-default .header {
  background: #FFF;
  height: 55px;
  display: flex;
  align-items: center;
}

.menu-default .header logo {
  display: table;
  margin: 0 auto;
}

.nav-item a {
  text-decoration: none !important;
}

.nav-items {
  flex: 0 0 100%;
}

.nav-item:not(:last-child) {
  border-bottom: solid 0.1px rgba(0, 0, 0, 0.2);
}

.nav-is-toggled .nav-drill {
  transform: translateX(0);
}

.nav-is-toggled::after {
  opacity: 1;
  visibility: visible;
}

.nav-link {
  display: block;
  padding: 0.875em 1em;
  background-color: #143a69;
  color: #fff;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 300;
  font-family: "IBM Plex Sans";
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav-link .material-icons {
  margin: 0 10px 0 0;
  font-size: 20px;
}

.center {
  display: flex;
  align-items: center;
}

.nav-expand-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  background-color: #208390;
  transition: 0.3s;
  visibility: hidden;
}

.nav-expand-content .nav-item:not(:last-child) {
  border-bottom: solid 1px #eee;
}

.nav-expand-content .nav-link {
  background-color: #daf9f4;
}

.nav-expand-content .nav-back-link::before {
  content: "chevron_left";
  margin-right: 0.5em;
  font-family: "Material Icons";
}

.nav-expand-link {
  display: flex;
  justify-content: space-between;
}

.nav-expand-link::after {
  content: "chevron_right";
  flex: 0 1 auto;
  font-family: "Material Icons";
}

.nav-expand.active > .nav-expand-content {
  transform: translateX(0);
  visibility: visible;
}

.nav-expand .nav-expand-content {
  background-color: #FFF;
}

.nav-expand .nav-expand-content .nav-link {
  background-color: transparent;
  font-weight: 500;
  border-bottom: 1px solid #eee;
}

/*.nav-expand .nav-expand-content .nav-expand-content {
	background-color: #FFF;
}
.nav-expand .nav-expand-content .nav-expand-content .nav-link {
	background-color: #daf9f4;
}
.nav-expand .nav-expand-content .nav-expand-content .nav-expand-content {
	background-color: #daf9f4;
}
.nav-expand .nav-expand-content .nav-expand-content .nav-expand-content .nav-link {
	background-color: #daf9f4;
}
.nav-expand .nav-expand-content .nav-expand-content .nav-expand-content .nav-expand-content {
	background-color: #daf9f4;
}
.nav-expand .nav-expand-content .nav-expand-content .nav-expand-content .nav-expand-content .nav-link {
	background-color: #59e1cb;
}*/
.nav-expand.active > .nav-expand-content {
  transform: translateX(0);
  visibility: visible;
}

.nav-expand-content .nav-link {
  color: #143a69;
}

.nav-back-link {
  display: flex;
  align-items: center;
  background-color: #143a69 !important;
  color: #fff !important;
  font-weight: 700 !important;
  padding: 15px 1em;
}

.nav-back-link img {
  margin-left: auto;
}

.nav-link-title {
  font-weight: bold !important;
  color: #208390 !important;
  font-weight: 700 !important;
  background: #FFF !important;
}

.nav-link-title a {
  color: #208390 !important;
  font-size: 12px;
}

.mat-drawer-container {
  height: 100%;
}

.mat-drawer {
  width: 230px;
  background: #143a69;
  -webkit-box-shadow: 3px 0 10px 0 #cecece;
  box-shadow: 3px 0 10px 0 #cecece;
  overflow-x: hidden;
}

.mat-drawer-content {
  z-index: 3 !important;
}

.mat-drawer.mat-drawer-side {
  z-index: 10 !important;
}

/** MENU **/
.menu ul {
  list-style: none;
  padding: 0;
}

.menu ul li a {
  color: #FFF !important;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  border-bottom: 1px solid #143a69;
  display: table;
  width: 100%;
  padding: 9px 10px;
  cursor: pointer;
}

.menu ul li a:hover {
  color: #208390 !important;
}

.menu ul li .seta {
  float: right;
}

.menu .sub-menu ul {
  display: none;
}

.menu .sub-menu ul.open {
  display: block;
}

.menu .sub-menu .item-of-sub-menu {
  background: #074068;
}

.menu .sub-menu .sub-menu {
  background: #074068;
}

.menu .sub-menu .item-of-sub-menu a {
  padding-left: 20px;
}

.menu .sub-menu li a {
  padding-left: 20px;
}

.menu .sub-menu li ul li a {
  padding-left: 30px !important;
}

.open .a-sub-itens {
  background: #053c63 !important;
}

.item-of-sub-menu {
  border-bottom: 0.5px solid #0e5384;
}

.mat-drawer-side {
  border-right: 0 !important;
}

.mat-drawer-inner-container {
  overflow-x: hidden !important;
}

.user-menu .item i {
  margin: 0 10px 0 0;
  color: #208390;
  position: relative;
  top: 7px;
}

.user-menu .item-button {
  background: #208390;
  text-align: center;
  margin: 2px;
  color: #FFF;
  border-radius: 5px;
}

.user-menu .item-button i {
  color: #FFF;
}

.user-menu .item-button:hover {
  color: #208390;
  background: #FFF;
  transition: all 0.5s ease-out;
}

.user-menu .center {
  display: flex;
  align-items: center;
}

/** Array de Cores **/
.form-custom label {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 700;
  margin: 0;
}

.form-custom input[type=text],
.form-custom input[type=password],
.form-custom input,
.form-custom textarea,
.form-custom mat-select {
  float: left;
  border: none;
  background: transparent;
  width: 100%;
  padding: 7px 0;
  border-radius: 0;
  font-size: 13px;
  -webkit-appearance: none;
  border-bottom: 1px solid #9D9D9C;
  outline: none !important;
}

.form-custom input::-ms-input-placeholder {
  font-size: 13px;
  font-family: "IBM Plex Sans";
}

.form-custom input::-webkit-input-placeholder {
  font-size: 13px;
  font-family: "IBM Plex Sans";
}

.form-custom input::placeholder {
  font-size: 13px;
  font-family: "IBM Plex Sans";
}

.form-custom textarea::-ms-input-placeholder {
  font-size: 13px;
  font-family: "IBM Plex Sans";
}

.form-custom textarea::-webkit-input-placeholder {
  font-size: 13px;
  font-family: "IBM Plex Sans";
}

.form-custom textarea::placeholder {
  font-size: 13px;
  font-family: "IBM Plex Sans";
}

.form-custom textarea {
  resize: none;
  -webkit-appearance: none;
}

.form-custom .form-group {
  display: table;
  width: 100%;
  margin: 0 0 15px 0;
  position: relative;
}

/** select 2 **/
.select2-container .select2-selection--single {
  height: 40px;
  outline: none !important;
  padding: 7px;
  background: #f6f6f6 !important;
  border-color: #eee;
  border-radius: 5px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  top: 80%;
  margin-left: -10px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #000;
  font-size: 12px;
}

.select2, .select2-hidden-accessible {
  width: 100% !important;
  max-width: 100% !important;
  table-layout: fixed;
  display: table;
}

.select2 {
  width: 100% !important;
}

.select2-results__option[aria-selected] {
  padding: 10px !important;
  font-size: 12px;
}

.select2-results__option[aria-selected] img {
  margin: 0 5px 0 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #208390 !important;
}

.select2-results__option.select2-results__message {
  font-size: 12px;
}

.mat-dialog-container {
  padding: 0 !important;
}

.modal-form,
.modal-view {
  position: relative;
  overflow: hidden;
}

.modal-form .modal-form-content,
.modal-view .modal-view-content {
  padding: 20px;
}

.modal-form-content {
  min-height: 200px;
}

.modal-form-content.no-padding-top {
  padding-top: 0 !important;
}

.modal-form label {
  margin: 0;
}

.modal-form input,
.modal-form textarea,
.modal-view .modal-view-content {
  font-size: 12px !important;
}

.modal-form textarea {
  padding: 10px 0 0 0;
}

/** TOOLBAR **/
.form-toolbar {
  padding: 5px 20px;
  border-bottom: 1px solid #eee;
}

.form-toolbar button mat-icon,
.form-toolbar-inline button mat-icon {
  font-size: 14px;
  width: auto !important;
  height: auto !important;
}

.form-toolbar button span {
  font-size: 11px;
  text-transform: uppercase;
}

.form-toolbar button {
  min-width: auto !important;
  padding: 7px 5px;
  line-height: 0;
  outline: none !important;
}

/** modal form **/
.modal-form .linha {
  display: table;
  margin: 20px 0;
  background: #eee;
  width: 100%;
  height: 1px;
}

.modal-form .title-h3 {
  color: #208390;
  font-weight: 600;
  font-size: 14px;
  margin: 10px 0 10px 0;
}

.modal-form .info {
  font-size: 12px;
}

/** DM CHECKBOX **/
.dm-check-box {
  display: flex;
  margin: 0 0 5px 0;
  align-items: center;
}

.dm-check-box-input {
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: table;
  border: 2px solid #eee;
  background: #FFF;
  margin: 0 10px 0 0;
  cursor: pointer;
}

.dm-check-box-input.active {
  background: #208390;
}

.dm-check-box-input,
.dm-check-box-value {
  display: table;
  float: left;
}

.dm-check-box-value {
  text-transform: uppercase;
  position: relative;
}

.dm-check-box-info {
  font-size: 10px;
  color: #208390;
  font-weight: 600;
}

/** Array de Cores **/
.btn {
  padding: 10px 10px;
  display: flex;
  align-items: center;
  border: 0;
  cursor: pointer;
  border-radius: 7px;
  outline: none;
  position: relative;
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 13px;
}

.btn i {
  font-size: 18px;
}

.btn-one {
  background: #208390;
  color: #FFF;
}

.btn-two {
  background: #f4f4f4;
  color: #000;
}

.btn-three {
  background: #e48900;
  color: #fff;
}

.btn-one:hover {
  color: #FFF;
}

.btn-layout {
  padding: 12px 10px 12px 30px;
}

.btn-layout span {
  margin-right: 40px;
}

.btn-icon i {
  margin: 0 5px 0 0;
}

.btn-six {
  background: #6800a9;
  color: #fff;
}

.btn-six:hover {
  color: #ca75ff !important;
}

.purple {
  background: #7300bb !important;
}

.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated-two {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animateLogo {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated-three {
  -webkit-animation-duration: 100ms;
  animation-duration: 100ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite, .animateLogo.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

/** Array de Cores **/
.mat-select-value-text,
.mat-option-text {
  font-size: 12px;
}

.mat-form-field label {
  float: left;
}

.mat-form-field-infix {
  padding-top: 0;
  border-top: 0;
}

.mat-form-field,
.mat-form-field-infix {
  width: 100% !important;
}

.mat-form-field-label {
  position: relative !important;
  font-size: 12px !important;
  top: 2.28125em;
}

.mat-form-field-label-wrapper {
  top: -1.24375em;
}

.mat-form-field .mat-form-field-empty.mat-form-field-label {
  display: block !important;
}

.mat-form-field .mat-form-field-label,
.mat-form-field.mat-focused .mat-form-field-label {
  display: none !important;
}

.mat-slider-horizontal {
  min-width: 100% !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-form-field-ripple {
  background-color: #143a69 !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #073d62 !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #143a69 !important;
}

.cdk-global-overlay-wrapper {
  pointer-events: auto !important;
  overflow-y: scroll !important;
  /*align-items: flex-start !important;*/
  z-index: auto !important;
}

.cdk-overlay-pane {
  margin: 1rem;
  z-index: 1002;
}

.cdk-overlay-backdrop {
  z-index: auto !important;
}

/** TABS **/
.mat-tab-header {
  background: #FFF;
}

.mat-tab-body-content {
  display: table;
  width: 100%;
  padding: 20px 0;
}

.mat-tab-label-content {
  font-size: 12px !important;
}

/** CHECKBOXS **/
mat-checkbox label {
  margin-bottom: 12px;
}

.mat-select-arrow {
  color: #143a69;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #143a69 !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #074068 !important;
}

.mat-slide-toggle-content {
  font-size: 12px;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 10000;
}

.mat-drawer-content {
  overflow-x: hidden;
}

/** Array de Cores **/
.modal-footer {
  border-top: 1px solid #eee;
  margin: 10px 0 0 0;
  padding: 10px 0 10px 0;
}

.modal-title {
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
}

.modal-title .text {
  font-weight: 600;
  flex: 1;
}

.modal-body {
  padding: 20px 20px;
  font-size: 12px;
}

.modal-title .item-close,
.dashboard-refresh {
  background: #208390;
  color: #FFF;
  display: flex;
  align-items: center;
  border-radius: 100%;
  font-size: 18px;
  padding: 5px;
  cursor: pointer;
}

/** Array de Cores **/
.btn-grid-toolbar {
  border: 0;
  padding: 5px 7px;
  display: flex;
  align-items: center;
}

.dm-grid-footer .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0 0 5px 0;
}

.total-grid {
  display: flex;
  align-items: center;
  background: #208390;
  padding: 4px 10px;
  border-radius: 8px;
  color: #FFF;
  font-size: 13px;
}

.total-grid i {
  margin: 0 5px 0 0;
}

.text-grid {
  font-size: 12px;
}

.total-passengers-grid {
  font-size: 13px;
  background: #143a69;
  border-radius: 100%;
  font-size: 12px;
  color: #FFF;
  display: table;
  width: 25px;
  height: 25px;
  text-align: center;
}

.total-passengers-grid span {
  position: relative;
  top: 4px;
}

/*** PAGE filter **/
.page-filter {
  background: #FFF;
  margin: 0 0 10px 0;
  border: 1px solid #eee;
  border-radius: 5px;
}

.page-filter-header {
  padding: 10px 10px;
  border-bottom: 1px solid #eee;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.page-filter-header span {
  flex: 1;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  color: #208390;
}

.page-filter-header i {
  color: #208390;
  font-size: 22px;
}

.page-filter-content {
  padding: 20px;
}

.page-filter-footer {
  border-top: 1px solid #eee;
  margin: 10px 0 0 0;
  padding: 10px 0 0 0;
}

.page-filter-footer .content {
  display: table;
  margin: 0 auto;
}

.page-filter-footer button {
  padding: 10px 20px !important;
  margin: 0 5px 0 0;
  float: left;
}

.page-filter-footer button i {
  margin: 0 10px 0 0;
}

.status-in-grid {
  color: #FFF;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.status-in-grid.green {
  background: #208390;
}

.status-in-grid.red {
  background: #d34242;
}

/** Array de Cores **/
.ui-datepicker:before {
  content: "";
  position: absolute;
  display: block;
  left: 20%;
  top: -11px;
  height: 0;
  width: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #208390;
  border-top: 0 solid transparent;
}

.ui-datepicker {
  width: 289px;
  margin-top: 7px;
  padding: 0;
  display: none;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: #9c9c9c 0 2px 4px;
  -moz-box-shadow: #9c9c9c 0 2px 4px;
  box-shadow: 0 2px 4px #9c9c9c;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1050 !important;
}

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: 0;
  height: 30px;
  background: #208390;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  border: 0;
}

.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 38px;
  text-align: center;
  color: #fff;
  font-size: 14px;
}

.ui-datepicker table th {
  padding: 8px 3px;
  text-align: center;
  border: 0;
  color: #fff;
  background: #208390;
  font-size: 12px;
  font-weight: 400;
}

.ui-datepicker table tbody td a, .ui-datepicker table tbody td span {
  display: block;
  text-decoration: none;
  text-align: right;
  color: #1c2b39;
  max-width: 100%;
  height: 40px;
  padding: 5px;
  z-index: 1;
  background: #FFF !important;
  border: 1px solid #eee !important;
  font-weight: bold !important;
}

.ui-datepicker table tbody td {
  border-style: solid;
  border-color: #eaf2fc;
  border-width: 1px 1px 0 0;
  font-size: 12px;
  color: #1c2b39 !important;
  font-weight: 700 !important;
  position: relative;
}

.ui-datepicker table {
  width: 100%;
  font-size: 0.9em;
  border-collapse: collapse;
  margin: 0;
}

.ui-datepicker table tbody {
  border: 1px solid #bcd5f5;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
  background: #c4e7ec !important;
  color: #208390;
}

.ui-datepicker-current-day::before {
  position: absolute;
  content: "task_alt";
  top: 22px;
  left: 3px;
  font-size: 13px;
  color: #143a69;
  z-index: 2;
  font-weight: 400;
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.ui-datepicker-next,
.ui-datepicker-prev {
  cursor: pointer !important;
}

/** cart date **/
.cart-date-datepicker .ui-datepicker {
  width: 100% !important;
  border: 0 !important;
}

.cart-date-datepicker .ui-datepicker table th {
  padding: 15px 3px;
}

.cart-date-datepicker .ui-datepicker table tbody td a,
.cart-date-datepicker .ui-datepicker table tbody td span {
  height: 50px !important;
}

.cart-date-datepicker .ui-datepicker::before {
  display: none !important;
}

html, body {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  height: 100%;
  line-height: 20px;
}

h1, h2, h3 {
  font-family: "Montserrat" !important;
}

.box-sombra {
  background: #FFF;
  box-shadow: 5px 5px 20px 0 #d4d4d4;
  padding: 20px;
}

.center-table {
  display: table;
  margin: 0 auto;
}

.page {
  padding: 70px 15px 100px;
}

.page-title-h1 {
  font-size: 16px;
  color: #208390;
  font-weight: 600;
}

.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.page-link {
  color: #143a69;
}

.row-center {
  align-items: center;
}

p {
  margin: 0;
}

.linha {
  background: #eee;
  margin: 10px 0;
  display: table;
  width: 100%;
  height: 1px;
}

.title-h3, .title-h4 {
  margin: 10px 0;
  font-size: 17px;
  color: #208390;
  font-weight: 600;
}

.title-h4 {
  font-size: 15px;
}

article h2 {
  font-size: 20px;
}

/** combo **/
.dm-combo-loader {
  display: table;
  width: 100%;
  position: relative;
}

.dm-combo-loader .btn-loader {
  padding: 3px;
  float: right;
  cursor: pointer;
  line-height: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.dm-combo-loader .info {
  float: left;
  font-size: 12px;
  margin: 0;
  padding: 5px;
  width: 87%;
  border-radius: 0;
}

.is-button-add {
  display: flex;
  align-items: center;
}

.is-button-add .combo-content-input {
  padding: 0 5px 0 0;
  position: relative;
  cursor: pointer !important;
  margin: 0 0 5px 0;
}

.is-button-add .content-button {
  position: relative;
  top: 4px;
}

.combo-content-input {
  position: relative;
}

.loader-message-combo {
  position: absolute;
  top: 0px;
  font-size: 12px;
  width: 100%;
  background: #208390;
  color: #FFF;
  padding: 12px;
  border-radius: 3px;
}

.page-body-auth .topo-layout {
  display: none !important;
}

.page-body-auth mat-drawer-content {
  margin: 0 !important;
}

.mat-drawer-container-has-open .page-cart .page-footer {
  padding-left: 250px !important;
}

.page-cart .page-footer button i {
  margin: 5px;
}

.bloco-table {
  display: table;
}

/** card **/
mat-card {
  padding: 0;
}

mat-card-title span {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 800;
}

mat-card mat-card-content {
  padding: 20px;
}

.ngx-gallery {
  display: inline-block;
  margin-bottom: 20px;
}

/** dashboard **/
.dashboard .dashboard-title {
  display: flex;
  align-items: center;
}

.dashboard .dashboard-title h3 {
  flex: 1;
  margin-top: 0;
}

.dashboard .dashboard-refresh {
  padding: 5px;
}

.dashboard .dashboard-refresh i {
  font-size: 18px;
}

.bg-blue {
  background: #143a69;
  border-radius: 5px;
  padding: 5px 10px;
  color: #FFF;
  font-size: 12px;
  min-width: 50px;
}

.bg-green {
  background: #208390;
  border-radius: 5px;
  padding: 5px 10px;
  color: #FFF;
  font-size: 12px;
  min-width: 50px;
}

/** payment link status **/
.payment-link-status-1, .payment-link-status-2, .payment-link-status-3 {
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  color: #FFF;
}

.payment-link-status-1 {
  background: #c93a0d;
}

.payment-link-status-2 {
  background: #6aaf10;
}

.payment-link-status-3 {
  background: #e7072e;
}

.card-item {
  padding: 0.8rem 1.3rem;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px #a7a7a7;
  border-radius: 4px;
}

/** card item **/
.card-item .header {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  padding: 0 0 10px 0;
  border-bottom: 1px solid #eee;
}

.card-item .header span {
  font-weight: bold;
  flex: 1;
  font-size: 14px;
}

.card-item .header .icon {
  cursor: pointer;
  border: 2px solid #eee;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.card-item .header i {
  font-size: 18px;
  color: #208390;
}

.cart-status-1 {
  background: #e2e2e2;
  color: #000;
}

.cart-status-2 {
  background: #0057a8;
}

.cart-status-3 {
  background: #6aaf10;
}

.cart-status-4 {
  background: #c7083e;
}