@import "colors.scss";
@import "fonts.scss";
@import "menu.scss"; 

.mat-drawer-container{
    height: 100%;
}
.mat-drawer{
    width: 230px;
    background: map-get($colors,one); 
    -webkit-box-shadow: 3px 0 10px 0 #cecece;
    box-shadow: 3px 0 10px 0 #cecece;
    overflow-x: hidden;
}
.mat-drawer-content{
    z-index: 3 !important;
}
.mat-drawer.mat-drawer-side{
    z-index: 10 !important;
}
/** MENU **/
.menu ul{
    list-style: none;
    padding: 0;
}
.menu ul li a{
    color: #FFF !important;
    font-size: 14px; 
    font-family: map-get($fonts,two);
    border-bottom: 1px solid map-get($colors,one);
    display: table;
    width: 100%;
    padding: 9px 10px;
    cursor: pointer;
}
.menu ul li a:hover{
    color: map-get($colors,two) !important;
}
.menu ul li .seta{
    float: right;
}
.menu .sub-menu ul{
    display: none; 
}
.menu .sub-menu ul.open{
    display: block;
}
.menu .sub-menu .item-of-sub-menu{
    background: map-get($colors,three);
}
.menu .sub-menu .sub-menu{
    background: map-get($colors,three); 
}
.menu .sub-menu .item-of-sub-menu a{
    padding-left: 20px;
}
.menu .sub-menu li a{
    padding-left: 20px;
}
.menu .sub-menu li ul li a{
   padding-left: 30px !important; 
}
.open .a-sub-itens{ 
    background: #053c63 !important;
}
.item-of-sub-menu{
    border-bottom: 0.5px solid #0e5384; 
}
.mat-drawer-side{
    border-right: 0 !important;
}
.mat-drawer-inner-container{
    overflow-x: hidden !important;
}
.user-menu .item i{
    margin: 0 10px 0 0;
    color: map-get($colors,two); 
    position: relative;
    top: 7px;
}
.user-menu .item-button{
    background: map-get($colors,two);
    text-align: center;
    margin: 2px;
    color: #FFF;
    border-radius: 5px;
}
.user-menu .item-button i{
    color: #FFF;
}
.user-menu .item-button:hover{
    color: map-get($colors,two);
    background: #FFF;
    transition: all 0.5s ease-out;;
}
.user-menu .center{
    display: flex;
    align-items: center;
}
