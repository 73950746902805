@import "./fonts.scss";
@import "./colors.scss";

.btn{
    padding: 10px 10px;
    display: flex;
    align-items: center;
    border: 0;
    cursor: pointer;
    border-radius: 7px;
    outline: none;
    position: relative;
    font-family: map-get($fonts,one);
    font-weight: 500;
    font-size: 13px;
}
.btn i{
    font-size: 18px;
}
.btn-one{
    background: map-get($colors,two);
    color: #FFF;
}
.btn-two{
    background: #f4f4f4;
    color: #000;
}
.btn-three{
    background: map-get($colors,nine);
    color: #fff;
}
.btn-one:hover{
    color: #FFF;
}
.btn-layout{
    padding: 12px 10px 12px 30px;
}
.btn-layout span{
    margin-right: 40px;
}
.btn-icon i{
    margin: 0 5px 0 0;
}
.btn-six{
    background: #6800a9;
    color: #fff;
}
.btn-six:hover{
    color: #ca75ff !important;
}
.purple{
    background: #7300bb !important;
}