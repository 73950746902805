@import "./colors.scss";   

.btn-grid-toolbar{
    border: 0;
    padding: 5px 7px;
    display: flex;
    align-items: center;
} 
.dm-grid-footer .mat-form-field-appearance-legacy .mat-form-field-infix{
    padding: 0 0 5px 0;
}
.total-grid{
    display: flex;  
    align-items: center;
    background: map-get($colors,two);
    padding: 4px 10px;
    border-radius: 8px; 
    color: #FFF;
    font-size: 13px;
}
.total-grid i{
    margin: 0 5px 0 0;
}
.text-grid{
    font-size: 12px;
}
.total-passengers-grid{
    font-size: 13px;
    background: map-get($colors,one);
    border-radius: 100%;
    font-size: 12px;
    color: #FFF;
    display: table;
    width: 25px;
    height: 25px;
    text-align: center;
}
.total-passengers-grid span{
    position: relative;
    top: 4px;
}
/*** PAGE filter **/
.page-filter{
    background: #FFF;
    margin: 0 0 10px 0;
    border: 1px solid #eee;
    border-radius: 5px;
}
.page-filter-header{
    padding: 10px 10px;
    border-bottom: 1px solid #eee;
    display: flex;
    cursor: pointer;
    align-items: center;
}
.page-filter-header span{
    flex: 1;
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    color: map-get($colors,two); 
}
.page-filter-header i{
    color: map-get($colors,two);
    font-size: 22px;
}
.page-filter-content{
    padding: 20px;
}
.page-filter-footer{
    border-top: 1px solid #eee;
    margin: 10px 0 0 0;
    padding: 10px 0 0 0;
}
.page-filter-footer .content{
    display: table;
    margin: 0 auto;
}
.page-filter-footer button{
    padding: 10px 20px !important;
    margin: 0 5px 0 0;
    float: left;  
}
.page-filter-footer button i{
    margin: 0 10px 0 0;
}
.status-in-grid{
    color: #FFF;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
}
.status-in-grid.green{
    background: map-get($colors,two);
}
.status-in-grid.red{
    background: map-get($colors,eight);
}