
/** Array de Cores **/
$colors : (
    one: #143a69,
    two: #208390,
    three: #074068,
    four: #c8c8c8,
    five: #073d62,
    six: #074068,
    seven: #074068,
    eight: #d34242,
    nine: #e48900,
    ten: #074068,
    eleven: #074068,
    twelve: #074068 ,
    thirteen: #3e9456,
    fourteen: #044a64,
    fifteen: #00394e
);