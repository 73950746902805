@import "colors.scss"; 

.form-custom label{
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 700; 
    margin: 0; 
}
.form-custom input[type="text"],
.form-custom input[type="password"],
.form-custom input,
.form-custom textarea,
.form-custom mat-select{
    float: left;
    border: none;
    background: transparent; 
    width: 100%;
    padding: 7px 0;
    border-radius: 0;
    font-size: 13px;
    -webkit-appearance: none;
    border-bottom: 1px solid #9D9D9C;
    outline: none !important;
}
.form-custom input::-ms-input-placeholder{
    font-size: 13px;
    font-family: map-get($fonts,one);
}
.form-custom input::-webkit-input-placeholder{
    font-size: 13px;
    font-family: map-get($fonts,one);
}
.form-custom input::placeholder{
    font-size: 13px;
    font-family: map-get($fonts,one);
}
.form-custom textarea::-ms-input-placeholder{
    font-size: 13px;
    font-family: map-get($fonts,one);
}
.form-custom textarea::-webkit-input-placeholder{
    font-size: 13px;
    font-family: map-get($fonts,one);
}
.form-custom textarea::placeholder{
    font-size: 13px;
    font-family: map-get($fonts,one);
}
.form-custom textarea{
    resize: none;
    -webkit-appearance: none;
}
.form-custom .form-group{
    display: table;
    width: 100%;
    margin: 0 0 15px 0;
    position: relative;
}
/** select 2 **/
.select2-container .select2-selection--single{
    height: 40px;
    outline: none !important;
    padding: 7px;
    background: #f6f6f6 !important;
    border-color: #eee;
    border-radius: 5px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b{
    top: 80%;
    margin-left: -10px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered{
    color: #000;
    font-size: 12px;
}
.select2,.select2-hidden-accessible{
    width: 100% !important;
    max-width: 100% !important;
    table-layout: fixed;
    display: table;
}
.select2{
    width: 100% !important;
}
.select2-results__option[aria-selected]{
    padding: 10px !important;
    font-size: 12px;
}
.select2-results__option[aria-selected] img{
    margin: 0 5px 0 0;
}
.select2-container--default .select2-results__option--highlighted[aria-selected]{
    background-color: map-get($colors,two) !important;
}
.select2-results__option.select2-results__message{
    font-size: 12px;
}
.mat-dialog-container{
    padding: 0 !important;
}
.modal-form,
.modal-view{
    position: relative;
    overflow: hidden;
}
.modal-form .modal-form-content,
.modal-view .modal-view-content{
    padding: 20px;
}
.modal-form-content{
    min-height: 200px;
}
.modal-form-content.no-padding-top{
    padding-top: 0 !important;
}
.modal-form label{
    margin: 0;
}
.modal-form input,
.modal-form textarea,
.modal-view .modal-view-content{
    font-size: 12px !important;
}
.modal-form textarea{
    padding: 10px 0 0 0;
}
/** TOOLBAR **/
.form-toolbar{  
    padding: 5px 20px;
    border-bottom: 1px solid #eee;
}
.form-toolbar button mat-icon,
.form-toolbar-inline button mat-icon{
    font-size: 14px;
    width: auto !important;
    height: auto !important;
}
.form-toolbar button span{
    font-size: 11px;
    text-transform: uppercase;
}
.form-toolbar button{
    min-width: auto !important;
    padding: 7px 5px;
    line-height: 0;
    outline: none !important; 
}
/** modal form **/
.modal-form .linha{
    display: table;
    margin: 20px 0;
    background: #eee;
    width: 100%;
    height: 1px;
}
.modal-form .title-h3{
    color: map-get($colors,two);
    font-weight: 600;
    font-size: 14px;
    margin: 10px 0 10px 0;
}
.modal-form .info{
    font-size: 12px;
}
/** DM CHECKBOX **/
.dm-check-box{
    display: flex;
    margin: 0 0 5px 0;
    align-items: center;
}
.dm-check-box-input{
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: table;
    border: 2px solid #eee;
    background: #FFF;
    margin: 0 10px 0 0;
    cursor: pointer;
}
.dm-check-box-input.active{
    background: map-get($colors,two);
}
.dm-check-box-input,
.dm-check-box-value{
    display: table;
    float: left;
}
.dm-check-box-value{
    text-transform: uppercase;
    position: relative;
}
.dm-check-box-info{
    font-size: 10px;
    color: map-get($colors,two);
    font-weight: 600;
}