@import "colors.scss";

.modal-footer{
    border-top: 1px solid #eee;
    margin: 10px 0 0 0;
    padding: 10px 0 10px 0;
}
.modal-title{
    padding: 10px 15px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
}
.modal-title .text{
    font-weight: 600;
    flex: 1;
}
.modal-body{
    padding: 20px 20px;
    font-size: 12px;
}
.modal-title .item-close,
.dashboard-refresh{ 
    background: map-get($colors,two);
    color: #FFF;
    display: flex;
    align-items: center;
    border-radius: 100%;
    font-size: 18px;
    padding: 5px;
    cursor: pointer;
}